import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import FassetBanner from '../../assets/images/fasset/fasset-banner.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Fasset extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={FassetBanner} alt="banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Making Finance Fairer & More Sustainable
                </div>
                <div className="subtitle section-margin">
                  A secure and frictionless gateway for people in frontier markets to own and grow
                  their wealth in digital assets
                </div>
                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Switch from Binance to a more flexible backend service provider and integrate
                      use cases to enhance the functionality of their crypto services.
                    </p>
                  </div>
                  <div className="mini-card ">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Managed a seamless migration from Binance to Fireblocks and integrated
                      Uniswap, among other features, to allow token swapping on the Ethereum
                      Blockchain.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      Improved user experience by managing the long processing time of Uniswap
                      transactions via asynchronous task queues. Also enhanced security of client’s
                      crypto assets by creating a sweeping logic.
                    </p>
                  </div>
                </div>
                <div className="col-11 mr-auto ml-auto ">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      The client is a global digital asset exchange that provides people in emerging
                      markets with the opportunity to invest and earn real value from crypto assets.
                      Their platform aims to enable free and easy digital asset trading for everyone
                      in the short term, while also creating opportunities for building stable
                      portfolios in the long term.
                    </p>
                    <br />
                    <p className="description">
                      When they approached Tintash, they were using Binance at their backend.
                      However, owing to Binance’s costly API and inflexibility in allowing for
                      integration of several essential use cases, the client was looking to migrate
                      to Fireblocks, another service provider for aggregation of different crypto
                      services that could also act as a custodial wallet.
                    </p>
                    <br />
                    <p className="description">
                      They wanted us to start with a proof of concept – write a minimalistic
                      integration layer to route some of their Binance facing API calls toward
                      Fireblocks and provide the Fireblocks-based equivalent actions’ APIs – in
                      order to validate whether Fireblocks would fulfill all their requirements.
                    </p>
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      We kickstarted the development process by running a quick discovery. The aim
                      was to ascertain how to use Fireblocks and which of their actions or services
                      could be mapped either directly or indirectly from the Binance use cases. Our
                      team conducted due diligence on the backend design and came up with a system
                      architecture.
                    </p>
                    <br />
                    <p className="description">
                      Using asynchronous messaging to communicate between the different subsystems,
                      the system would pass on users’ requests to Fireblocks much like those were
                      being passed on to Binance. This was accomplished using a minimal interfacing
                      and integration layer i.e. there was no change to the rest of the application
                      except for the backend.
                    </p>
                    <br />
                    <p className="description">
                      The next step was to convert this minimal implementation into a full-fledged
                      service. What the client now wanted was a stateful service where it was not
                      just an interfacing layer but instead a proper backend application that could
                      handle contextual application data and business logic.
                    </p>
                    <br />
                    <p className="description">
                      For instance, when a user requests to run a transaction, we would make sure
                      that there were enough funds in the system and that the currencies were
                      correct. We would keep track of external facing wallets such as the
                      withdrawal, swapping and staking wallets. We would also ensure that once the
                      transaction was complete, the respective update services were called i.e.
                      ledger and user accounts were all updated.
                    </p>
                    <br />
                    <p className="description">
                      This greater functionality would also help reduce the API load on
                      third-parties and avoid rate limits. Towards this end, our team came up with a
                      system design comprising the Omnibus architecture which had been proposed by
                      Fireblocks itself. With the new service, users could now interact with the
                      client’s crypto services (business logic) while their actions were still
                      passed on to Fireblocks.
                    </p>
                    <br />
                    <p className="description">
                      Having successfully converted the initial implementation into a full service,
                      our next job was to enhance the functionality of existing crypto services.
                      Delivering on all the requirements put forth by the client meant going above
                      and beyond the features supported by Fireblocks.
                    </p>
                    <br />
                    <p className="description">
                      One of such features was the swapping of tokens. Since these swaps were not
                      supported by Fireblocks by default, we worked on integrating Uniswap, a
                      service provider that allowed this swapping. With this successful integration,
                      users could now swap tokens on the Ethereum Blockchain. It was as seamless as
                      creating any other transaction where all the related actions such as ledger
                      and wallet updating would be automatically taken care of.
                    </p>
                    <br />
                    <p className="description">
                      There was, however, one serious problem we encountered with Uniswap
                      integration – the swapping transactions were taking far too long to complete.
                      We simply couldn’t afford an average response time of between 5 to 10 minutes
                      for every such transaction for our users. One way around this problem was to
                      make swaps asynchronous transactions.
                    </p>
                    <br />
                    <p className="description">
                      What we did was essentially change Uniswap transactions into long running
                      actions. Instead of a completion status of their transaction, users would now
                      get an immediate update that their transaction had been accepted, together
                      with their request IDs. Meanwhile, the transaction would move to long running
                      actions where backend workers would take care of all the callbacks or status
                      updates on users’ behalf. And only once a transaction had been completed from
                      Uniswap’s end would we let the ledger, the wallets, and the users know that
                      they had received funds in their accounts.
                    </p>
                    <br />
                    <p className="description">
                      Apart from token swaps, another feature the client requested was having one
                      consolidated vault for all their users’ funds. Suppose there are 10 users with
                      $10 worth of funds each in their respective wallets. What the client would do
                      is take all these $100 worth of funds and put them in a central vault, much
                      like what your bank does when you deposit funds in your bank account.
                    </p>
                    <br />
                    <p className="description">
                      For this, we created a sweeping logic which was again a backend task that ran
                      every defined number of hours. When users received funds, they would end up in
                      their wallets. But as soon as they did, there would be a sweeping action that
                      swept the user wallets into a more secure central vault.  On the paper or
                      ledger, users would still own their funds and could do all the transactions.
                      It was just the physical location of the funds that was moved centrally.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      All in all, our team managed the client’s successful migration from Binance to
                      Fireblocks as the backend layer and built a stateful service using our own
                      business logic on top of it. We also enhanced the functionality of their
                      crypto services by including token swapping, as well as improved their asset
                      security by writing the sweeping action.
                    </p>
                    <br />
                    <p className="description">
                      Tintash’s close partnership with this client remains ongoing. Our team is
                      currently engaged on solving two critical problems for this client.
                    </p>
                    <br />
                    <p className="description">
                      Firstly, we are helping them further expand the scope of their crypto services
                      by allowing for cross-chain swaps i.e. exchanging ETH for BTC, or ETH or BTC
                      for Polygon. Much like we had integrated Uniswap to allow users to swap funds
                      while remaining on the Ethereum Blockchain, we are in the process of
                      integrating SimpleSwap, a cross-chain service provider, which will allow users
                      to exchange one chain’s funds with another chain’s.
                    </p>
                    <br />
                    <p className="description">
                      Secondly, we are integrating public readers into their platform. While running
                      Uniswap transactions, Fireblocks’ update callbacks were missing a lot of
                      critical information. This was so essential a feature since it enabled sending
                      users an update on how much funds they had received. Such a major lapse on
                      Fireblocks’ end, now our client’s main backend provider, posed a critical
                      problem.
                    </p>
                    <br />
                    <p className="description">
                      Our team has been working on a solution that circumvents Fireblocks for this
                      particular use case. Since the information of these transactions was available
                      on the blockchain and public readers, we ran a quick discovery to identify the
                      five leading public network readers, compare their feature sets and cost
                      estimates. Out of those five we proposed, the client selected Etherscan and
                      Ankr, the two public readers we are currently in the process of integrating.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Fasset;
